import { Link } from "react-router-dom";

type Props = {
  text: string;
  to: string;
  stateHandler: () => void;
};

function NavItem({ text, to, stateHandler }: Props) {
  return (
    <Link
      to={to}
      onClick={stateHandler}
      className="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
    >
      {text}
    </Link>
  );
}

export default NavItem;
