import LinkButton from "../components/Navigation/LinkButton";
import PageHeader from "../components/Navigation/PageHeader";
import typingBio from "../images/typing-bio.svg";

function Home() {
  return (
    <div className="m-auto text-center">
      <PageHeader text="Hayden Moritz" />
      <img
        className="m-auto mt-8"
        src={typingBio}
        alt="Animated text typing Hayden Moritz's bio"
      />
      <div className="flex flex-wrap justify-center space-x-8 max-xsm:space-x-0 xsm:mt-8">
        <LinkButton text="$> About" to="/about" />
        <LinkButton text="$> Links" to="/links" />
        <LinkButton text="$> Contact" to="/contact" />
      </div>
    </div>
  );
}

export default Home;
