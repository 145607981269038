import { Outlet } from "react-router-dom";
import Canvas from "../components/Canvas/Canvas";
import CanvasContent from "../components/Canvas/CanvasContent";
import NavBar from "../components/Navigation/NavBar";
import Footer from "../components/Footer/Footer";

function Layout() {
  return (
    <Canvas>
      <>
        <NavBar />
        <CanvasContent>
          <Outlet />
        </CanvasContent>
        <Footer />
      </>
    </Canvas>
  );
}

export default Layout;
