import { Link } from "react-router-dom";
import largeLogo from "../../images/logo-large.svg";

type Props = {
  stateHandler: () => void;
};

function NavLogo({ stateHandler }: Props) {
  return (
    <Link to="/" onClick={stateHandler}>
      <img src={largeLogo} alt="Large logo depicting hmoritz.dev"></img>
    </Link>
  );
}

export default NavLogo;
