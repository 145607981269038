import NavItem from "./NavItem";

type Props = {
  stateHandler: () => void;
};

function NavItemGroup({ stateHandler }: Props) {
  return (
    <>
      <NavItem text="About" to="/about" stateHandler={stateHandler} />
      <NavItem text="Links" to="/links" stateHandler={stateHandler} />
      <NavItem text="Contact" to="/contact" stateHandler={stateHandler} />
    </>
  );
}

export default NavItemGroup;
