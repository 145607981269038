type Props = {
  children: JSX.Element;
};

function CanvasContent({ children }: Props) {
  return (
    <div className="mx-auto flex h-[calc(var(--doc-height)-128px)] max-w-7xl overflow-y-auto px-4 sm:px-6 lg:px-8">
      {children}
    </div>
  );
}

export default CanvasContent;
