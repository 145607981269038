import { Link } from "react-router-dom";

type Props = {
  text: string;
  to: string;
};

function LinkButton({ text, to }: Props) {
  return (
    <Link
      to={to}
      className="inline-block w-36 rounded bg-green-500 py-2.5 text-lg font-bold uppercase text-black transition duration-150 ease-in-out hover:bg-green-700 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg max-xsm:mt-8 max-xsm:w-9/12"
    >
      <button type="button">{text}</button>
    </Link>
  );
}

export default LinkButton;
