import PageHeader from "../components/Navigation/PageHeader";

function About() {
  return (
    <div className="m-auto text-center">
      <PageHeader text="About Page" />
    </div>
  );
}

export default About;
