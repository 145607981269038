import PageHeader from "../components/Navigation/PageHeader";

function Links() {
  return (
    <div className="m-auto text-center">
      <PageHeader text="Links Page" />
    </div>
  );
}

export default Links;
