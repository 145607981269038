import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  SOURCE_CODE_URL,
  GITHUB_URL,
  LINKEDIN_URL,
} from "../../constants/Constants";

function Layout() {
  return (
    <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
      <p className="p-2 text-gray-300">
        &copy; {new Date().getFullYear()} Hayden Moritz
      </p>
      <FontAwesomeIcon color="#000000" icon="linkedin" />
      <div className="flex">
        {/* LinkedIn */}
        <a
          className="block p-2 md:p-3"
          href={LINKEDIN_URL}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon
            className="text-gray-300 opacity-50"
            icon={faLinkedin}
          />
        </a>
        {/* GitHub */}
        <a
          className="block p-2 md:p-3"
          href={GITHUB_URL}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon
            className="text-gray-300 opacity-50"
            icon={faGithub}
          />
        </a>
        {/* Source Code */}
        <a
          className="block p-2 md:p-3"
          href={SOURCE_CODE_URL}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon className="text-gray-300 opacity-50" icon={faCode} />
        </a>
      </div>
    </div>
  );
}

export default Layout;
