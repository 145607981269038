import Lottie from "lottie-react";
import glitch404 from "../lotties/glitch-404.json";

function NoPage() {
  return (
    <div className="m-auto text-center">
      <div className="m-auto h-1/2 w-1/2">
        <Lottie
          animationData={glitch404}
          loop={true}
          alt="404 Page Not Found"
        />
      </div>
    </div>
  );
}

export default NoPage;
