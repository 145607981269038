import PageHeader from "../components/Navigation/PageHeader";

function Contact() {
  return (
    <div className="m-auto text-center">
      <PageHeader text="Contact Page" />
    </div>
  );
}

export default Contact;
