import { useState } from "react";
import NavLogo from "./NavLogo";
import NavItemGroup from "./NavItemGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <nav className="bg-gray-900 shadow-md">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="content-start">
              <NavLogo stateHandler={() => setIsOpen(false)} />
            </div>
            <div className="hidden content-end sm:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <NavItemGroup stateHandler={() => setIsOpen(false)} />
              </div>
            </div>
            <div className="-mr-2 flex sm:hidden">
              <button
                className="block cursor-pointer border-solid border-transparent bg-transparent px-3 py-1 text-xl leading-none text-white outline-none focus:outline-none sm:hidden"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
              >
                {!isOpen ? (
                  <FontAwesomeIcon className="opacity-50" icon={faBars} />
                ) : (
                  <FontAwesomeIcon className="opacity-50" icon={faX} />
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="absolute w-full space-y-1 bg-gray-900 px-2 pt-2 pb-3 shadow-md sm:hidden sm:px-3">
            <NavItemGroup stateHandler={() => setIsOpen(false)} />
          </div>
        )}
      </nav>
    </>
  );
}

export default NavBar;
